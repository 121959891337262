<template>
    <v-dialog transition="dialog-top-transition" max-width="1200" v-model="showDialog">
        <v-card>
            <v-card-text class="py-0 px-0">
                <v-form ref="formEditUser" lazy-validation>
                    <v-alert border="right" colored-border color="#8e84c0" elevation="0"
                        class="col-12 px-5 py-5 pb-4 mb-0">
                        <v-col class="px-1 pt-0 m-0 text-left">
                            <v-row no-gutters>
                                <v-col cols="12" class="px-0 pt-0 mt-0 text-left">
                                    <span class="title-emails">
                                        Información de geografica
                                    </span>
                                    <br>
                                    <v-divider class="mt-2"></v-divider>
                                </v-col>

                                <v-col cols="12" class="pa-0">
                                    <v-tabs v-model="activeTab" color="#8e84c0"
                                        class="round-tab mb-6 d-flex justify-left" :slider-color="'#8e84c0'">
                                        <v-tab v-for="(tab, index) in tabs" :key="index" class="custom-tab">
                                            {{ tab }}
                                        </v-tab>
                                    </v-tabs>

                                    <v-data-table v-show="activeTab === 0" :headers="headersCountry" :items="countryData"
                                        :loading="loadingCountry" :items-per-page="perPageCountries"
                                        :server-items-length="totalCountries" :page.sync="pageCountries"
                                        :footer-props="{ itemsPerPageOptions: [15, 100, 200, 300, -1], showFirstLastPage: true }"
                                        @update:page="changePage('countries', $event)"
                                        @update:items-per-page="changePerPage('countries', $event)">
                                        <template v-slot:item.name="{ item }">
                                            <div class="item-container">
                                                <span>{{ item.name }}</span>
                                            </div>
                                        </template>
                                        <template v-slot:item.total="{ item }">
                                            <span>{{ formatNumber(item.total) }}</span>
                                        </template>
                                        <template v-slot:item.flag="{ item }">
                                            <v-img class="flags" :src="item.flag" />
                                        </template>
                                        <template v-slot:item.percentage="{ item }">
                                            <v-chip color="#f4e8ff">
                                                {{ item.percentage }}%
                                            </v-chip>
                                        </template>
                                    </v-data-table>

                                    <v-data-table v-show="activeTab === 1" :headers="headersRegion" :items="regionData"
                                        :loading="loadingRegion" :items-per-page="perPageCities"
                                        :server-items-length="totalRegions" :page.sync="pageRegions"
                                        :footer-props="{ itemsPerPageOptions: [15, 100, 200, 300, -1], showFirstLastPage: true }"
                                        @update:page="changePage('regions', $event)"
                                        @update:items-per-page="changePerPage('regions', $event)">
                                        <template v-slot:item.name="{ item }">
                                            <div class="item-container">
                                                <span>{{ item.region }}</span>
                                            </div>
                                        </template>
                                        <template v-slot:item.total="{ item }">
                                            <span>{{ formatNumber(item.total) }}</span>
                                        </template>
                                    </v-data-table>

                                    <v-data-table v-show="activeTab === 2" :headers="headersCity" :items="cityData"
                                        :loading="loadingCity" :items-per-page="perPageRegions"
                                        :server-items-length="totalCities" :page.sync="pageCities"
                                        :footer-props="{ itemsPerPageOptions: [15, 100, 200, 300, -1], showFirstLastPage: true }"
                                        @update:page="changePage('cities', $event)"
                                        @update:items-per-page="changePerPage('cities', $event)">
                                        <template v-slot:item.name="{ item }">
                                            <div class="item-container">
                                                <span>{{ item.city }}</span>
                                            </div>
                                        </template>
                                        <template v-slot:item.total="{ item }">
                                            <span>{{ formatNumber(item.total) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-alert>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from '@/axios.js';
import  { getCountryFlagUrl } from "@/utils/countries.js";

export default {
    props: {
        dialogShow: {
            type: Boolean,
            required: true,
        },
        activeTab: {
            type: Number,
            default: 0,
        },
        tabs: { type: Array, default: () => ['Países', 'Regiones', 'Ciudades'] },
        year: {
            default: new Date().getFullYear(),
        },
    },
    data() {
        return {
            countryData: [],
            regionData: [],
            cityData: [],
            loadingCountry: false,
            loadingRegion: false,
            loadingCity: false,
            totalCountries: 0,
            totalRegions: 0,
            totalCities: 0,
            pageCountries: 1,
            pageRegions: 1,
            pageCities: 1, // Variables independientes
            perPageCountries: 15,
            perPageRegions: 15,
            perPageCities: 15,
            headersCountry: [
                { text: '', value: 'flag' },
                { text: 'Pais', value: 'name' },
                { text: 'Total', value: 'total' },
                { text: 'Porcentaje', value: 'percentage' },
            ],
            headersCity: [
                { text: 'Ciudad', value: 'name' },
                { text: 'Total', value: 'total' },
            ],
            headersRegion: [
                { text: 'Region', value: 'name' },
                { text: 'Total', value: 'total' },
            ],
            rules: {
                required: (value) => !!value || 'Este campo es requerido',
            },
        }
    },
    computed: {
        showDialog: {
            get() {
                return this.dialogShow;
            },
            set(value) {
                this.$emit("update:dialogShow", value);
            },
        },
    },
    watch: {
        activeTab(newTab) {
            if (this.showDialog) {
                if (newTab === 0) {
                    this.fetchCountries();
                } else if (newTab === 1) {
                    this.fetchRegions();
                } else if (newTab === 2) {
                    this.fetchCities();
                }
            }
        },
        showDialog(newVal) {
            if (newVal) {
                if (this.activeTab === 0) {
                    this.fetchCountries();
                } else if (this.activeTab === 1) {
                    this.fetchRegions();
                } else if (this.activeTab === 2) {
                    this.fetchCities();
                }
            }
        }
    },
    methods: {
        fetchCountries() {
            this.loadingCountry = true;
            axios.get('/admin/countries', {
                params: {
                    year: this.year,
                    page: this.pageCountries,
                    per_page: this.perPageCountries,
                    search: this.search,
                    order: this.order,
                    filter: this.filter
                }
            })
                .then(response => {
                    this.countryData = 
                        response.data.data.map(item => ({
                            name: item.normalized_country,
                            total: item.total,
                            percentage: item.percentage,
                            flag: getCountryFlagUrl(item.normalized_country)
                        }))
                    this.totalCountries = response.data.total;
                })
                .catch(error => {
                    console.error("Error fetching countries:", error);
                })
                .finally(() => {
                    this.loadingCountry = false;
                });
        },
        fetchRegions() {
            this.loadingRegion = true;
            axios.get('/admin/regions', {
                params: {
                    year: this.year,
                    page: this.pageRegions,
                    per_page: this.perPageRegions,
                    search: this.search,
                    order: this.order,
                    filter: this.filter
                }
            })
                .then(response => {
                    this.regionData = response.data.data;
                    this.totalRegions = response.data.total;
                })
                .catch(error => {
                    console.error("Error fetching regions:", error);
                })
                .finally(() => {
                    this.loadingRegion = false;
                });
        },
        fetchCities() {
            this.loadingCity = true;
            axios.get('/admin/cities', {
                params: {
                    year: this.year,
                    page: this.pageCities,
                    per_page: this.perPageCities,
                    search: this.search,
                    order: this.order,
                    filter: this.filter
                }
            })
                .then(response => {
                    this.cityData = response.data.data;
                    this.totalCities = response.data.total;
                })
                .catch(error => {
                    console.error("Error fetching cities:", error);
                })
                .finally(() => {
                    this.loadingCity = false;
                });
        },
        changePage(type, newPage) {
            if (type === 'countries') {
                this.pageCountries = newPage;
            } else if (type === 'regions') {
                this.pageRegions = newPage;
            } else if (type === 'cities') {
                this.pageCities = newPage;
            }
            this.fetchData(type);
        },
        changePerPage(type, newPerPage) {
            if (type === 'countries') {
                this.perPageCountries = newPerPage;
                this.pageCountries = 1; // Resetear a la primera página
            } else if (type === 'regions') {
                this.perPageRegions = newPerPage;
                this.pageRegions = 1;
            } else if (type === 'cities') {
                this.perPageCities = newPerPage;
                this.pageCities = 1;
            }
            this.fetchData(type);
        },
        fetchData(type) {
            switch (type) {
                case 'countries':
                    this.fetchCountries();
                    break;
                case 'regions':
                    this.fetchRegions();
                    break;
                case 'cities':
                    this.fetchCities();
                    break;
                default:
                    console.error("Invalid type for fetchData:", type);
            }
        },
        formatNumber(value) {
            return new Intl.NumberFormat().format(value);
        }
    }
}
</script>

<style scoped>
.title-emails {
  color: #8061c2;
  font-weight: 700;
  font-size: 20px;
}

.harmony-name {
  color: #8061c2;
  font-weight: 800;
  font-size: 18px;
}

.round-tab {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.custom-tab {
    text-transform: none;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 16px;
}

.flags {
    width: 21px !important;
}
.chip-container {
    display: flex;
    flex-wrap: wrap;  /* Permite que se dividan en múltiples filas */
    max-height: 100px; /* Ajusta esta altura si es necesario */
    overflow-x: auto;  /* Scroll horizontal */
    background-color: #f4e8ff;
    border-radius: 12px;
}

.title-desc {
    font-size: 15px;
    color: #676f7b;
}


 ::v-deep .v-chip__content{
    font-weight: 800;
    color: #8e84c0;
    font-size: 10px;
    height: 18px !important;
}
::v-deep .v-chip {
    height: 15px !important;
}

.custom-tab {
    text-transform: none;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 16px;
}

.scroll-data {
    height: 260px;
    overflow-y: auto;
}
.round-tab {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.title-card {
    color: #616181;
    font-weight: 400;
    font-size: 26px;
}

.d-card {
    background-color: #fff4de;
}

.d-card2 {
    background-color: #dcfce7;
}

.d-card3 {
    background-color: #f4e8ff;
}

.title-dashboard {
    color: #8061c2;
    font-weight: 500;
    margin-left: 10px;
    margin-top: 8px;
}

.desc-dashboard {
    color: gray;
    font-size: 14px;
    margin-left: 10px;
}

.title {
    font-size: 22px !important;
    color: #8061c2;
}

.desc {
    color: #737373;
    font-size: 15px;
}
.icon-maps {
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>